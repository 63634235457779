@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg:#ffffff;
    --color-bg-variant: rgb(188, 189, 190);
    --color-primary: #000000;
    --color-primary-variant: rgb(43, 44, 44);
    --color-white: rgb(0, 0, 0);
    --color-light: rgb(255, 255, 255);

    --transition: all 400ms ease;

    --container-with-lg: 75%;
    --container-with-md: 86%;
    --container-with-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    /* background-image: url(../src/assets/bp-texture3.png); */
    background-size: 100;
}

/* ====== GENERAL STYLES ====== */

.container {
    max-width: var(--container-with-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
    padding-top: 3rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-primary-variant);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 4rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (min-width: 1024px) {
    .container {
        width: var(--container-with-md);
        
    }
    
    section {
        margin-top: 6rem;
    }
}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-with-sm);
        max-width: none;
    }
    
    section > h2 {
        margin-bottom: 2rem;
    }
}