header {
    height: 100vh;
    padding: 2rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 70vh;
    position: relative;
}

/* ====== HEADER SOCIALS ====== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: -3.3rem;
    bottom: 3rem;
    font-size: 2rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ====== imgHeader ====== */
.imgHeader {
    width: 100%;
    height: 70vh;
    position: absolute;
    overflow: hidden;
    border-radius: 5px;
    background-image: url(../../assets/imgHeaderWeb-min.jpg);
    background-size: cover;
}

.conteinerWhats {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 4rem;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    box-shadow: 1px 1px 2px #888;
    z-index: 1000;
    margin-right: -30px;
}

/* ====== SCROLL DOWN ====== */
.scroll__down {
    position: absolute;
    right: -6rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 1.2rem;
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 1024px) {
    header {
        margin-top: 3rem;
        height: 100vh;
        padding: 0;
    }
    .header__socials,
    .scroll__down {
        display: none;
    }
    .imgHeader {
        background-image: url(../../assets/imgHeaderMobile-min.jpg);
    }
    .conteinerWhats {
        bottom: 2rem;
        right: 3rem;
        width: 50px;
        height: 50px;
    }
}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 600px) {
    header {
        margin-top: 3rem;
        height: 100vh;
        padding: 0;

    }
    .header__socials,
    .scroll__down {
        display: none;
    }
    .imgHeader {
        background-image: url(../../assets/imgHeaderMobile-min.jpg);
    }
    .conteinerWhats {
        bottom: 2rem;
        right: 3rem;
    }
}