.about__container {
    display: flex;
    gap: 2%;
}

.imgAbout > img{
    margin: 1rem;
    width: 35rem;
    border-radius: 2px;
    background-size: cover;
}

.about__container > div > p{
    text-align: start;
    text-indent: 1rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 1024px) {
    .about__container {
        flex-direction: column;
    }
    .imgAbout{
        display: flex;
        justify-content: center;
    }
    .imgAbout > img{
        width: 20rem;
        
    }

}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 600px) {
    .about__container {
        flex-direction: column;
    }
    .imgAbout{
        display: flex;
        justify-content: center;
    }
    .imgAbout > img{
        width: 20rem;
        
    }
}