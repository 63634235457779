.services__container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.service{
    background: transparent;
    border-color: var(--color-primary-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
    cursor: default;
}

section > h3{
    text-align: center;
    color: var(--color-primary-variant);
    margin-bottom: 1rem;
}

.headServices{
    margin: 0 12rem;
    color: var(--color-primary-variant);
    margin-bottom: 1rem;
}
.introServices > p{
    color: var(--color-primary-variant);
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 15px;
    width: 20rem;
    height: 15rem;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    flex-direction: column;
}

.introServices > p > i{
    font-size: 3rem;
}

.service__head {
    background: var(--color-primary-variant);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0,0,0,5%);
}

.service__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.service__list {
    padding: 2rem;
}

.service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.service__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}

.service__list p {
    font-size: 0.9rem;
}

.secHeader{
    background-color: var(--color-primary-variant);
    color: var(--color-bg);
    padding: 20px;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 1rem 1rem rgb(0 0 0 / 10%);
}

.introServices{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.contentServices{
    display: flex;
    margin: auto;
    margin-bottom: 4rem;
    width: 40vw;
}

.contentServices > h4{
    text-align: center;
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 1024px) {
    .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .service {
        height: auto;
    }

    .introServices{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .introServices > p{
        height: 10rem;
    }
    .contentServices{
        width: 80vw;
    }
}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 600px) {
    .services__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .headServices{
        margin: 0 2rem;
        color: var(--color-primary-variant);
        margin-bottom: 1rem;
    }
    .headServices > p{
        margin: 1rem 0;
        margin-left: 1rem;
    }
    
}