nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50vw;
    transform: translateX(-50%);
    bottom: 1rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

nav a:hover {
    background: var(--color-primary);
    color: var(--color-bg);
    /* background: rgba(0, 0, 0, 0.3); */
}

nav a.active {
    background: var(--color-primary);
    color: var(--color-bg);
    /* background: rgba(0, 0, 0, 0.3); */
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 1024px) {
    nav {
        bottom: 0.5rem;
        /* width: 70vw;
        gap: 0.5rem */
    }
/* 
    nav a {
        padding: 0.5rem;
    } */
}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 600px) {
    nav {
        bottom: 0.5rem;
    }
}

