.container.contact__container {
    
  width: 58%;
  display: grid;
    grid-template-columns: 30% 58%;  
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    /* background: var(--color-primary-variant); */
    border-color: var(--color-primary-variant);
    color: var(--color-primary-variant);
    padding: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    background-color: var(--color-primary-variant);
    border-radius: 100%;
    color: white;
    padding: 15px;
    width: 4rem;
    height: 4rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* ================ FORM ================ */
form {
    margin: 0.3rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

/* ================ MSG =============== */
.msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    color: var(--color-light);
    border-radius: 20px;
    padding: 1.2rem;
    opacity: 0;
    transition: opacity .3s ease-in-out;
}

.msg.success {
    background: rgba(63, 135, 75, 1);
    opacity: 1;
}

.msg.error {
    background: rgba(209, 51, 51, 1);
    opacity: 1;
}

/* ====== MEDIA QUERIES (MEDIUM DEVICES) ====== */
@media screen and (max-width: 1024px) {
    
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

}

/* ====== MEDIA QUERIES (SMALL DEVICES) ====== */
@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
        grid-template-columns: 1fr;
    }
}